import {
	ENV_CONFIG,
	EnvironmentConfig
} from "@core/environments/environment-config";
import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { AbTest } from "@data/schemas/ab-test";
import { AbTestChosen } from "@data/schemas/ab-test-chosen";
import { BehaviorSubject, map, Observable } from "rxjs";
import { DataList } from "@data/schemas/filter/data-list";
import { BaseCrudBffService } from "@shared/services/base-crud-bff.service";
import { AuthService } from "@core/services/auth/auth.service";

@Injectable({
	providedIn: "root"
})
export class AbTestService {
	readonly AB_TESTS_ENDPOINT = "ab-tests";
	API_URL = "";
	API_BFF_URL = "";

	private abTests = new BehaviorSubject<AbTestChosen[]>([]);
	abTests$ = this.abTests.asObservable();

	constructor(
		protected http: HttpClient,
		private baseCrudBff: BaseCrudBffService,
		private authService: AuthService,
		@Inject(ENV_CONFIG) private config: EnvironmentConfig
	) {
		this.API_URL = `${config.environment.API}`;
		this.API_BFF_URL = `${config.environment.API_BFF}`;
	}

	setAbTests(items: AbTestChosen[]): void {
		this.abTests.next(items);
	}

	getAbTestByName(name: string): Observable<AbTestChosen | undefined> {
		return this.abTests$.pipe(
			map((data) =>
				data.find((abTestChosen) => abTestChosen.name === name)
			)
		);
	}

	getChosensByDistinctId(
		distinctId: string
	): Observable<DataList<AbTestChosen>> {
		const params = new HttpParams()
			.set(
				"hashedFingerprint",
				this.authService.getHashedFingerprint() ?? ""
			)
			.set("distinctId", distinctId);
		return this.http.get<DataList<AbTestChosen>>(
			`${this.API_BFF_URL}/${this.AB_TESTS_ENDPOINT}/chosens-by-distinctid`,
			{ params, withCredentials: true }
		);
	}

	getAllAbTest(): Observable<DataList<AbTest>> {
		return this.baseCrudBff.getAll<DataList<AbTest>>({
			path: this.AB_TESTS_ENDPOINT
		});
	}
}
