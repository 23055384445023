import { Injectable } from "@angular/core";
import { SidebarSubmenu } from "@shared/schemas/sidebar-submenu";
import { BaseCrudBffService } from "@shared/services/base-crud-bff.service";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
	providedIn: "root"
})
export class MenuService {
	private readonly MENU_ENDPOINT = "auth/list-menus";

	private menuList = new BehaviorSubject<SidebarSubmenu | undefined>(
		undefined
	);
	menuList$ = this.menuList.asObservable();

	constructor(private baseCrudBff: BaseCrudBffService) {}

	loadMenus(): Observable<SidebarSubmenu> {
		return this.baseCrudBff.getAll<SidebarSubmenu>({
			path: this.MENU_ENDPOINT
		});
	}

	setMenuList(menuList: SidebarSubmenu) {
		this.menuList.next(menuList);
	}
}
